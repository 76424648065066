import { EntryCollection } from "contentful";
import { getBeardItems } from "src/crud/crud";
import { IBeardItem } from "../declarations/app";
import { DataStatus } from "../declarations/enum";

export interface IBeardItemsState {
  beardItems: EntryCollection<IBeardItem> | null;
  beardItemsStatus: DataStatus;
  getBeardItems: (lng: string) => void;
};

export const createBeardItemsState: (...args: any) => IBeardItemsState = (set) => ({
  beardItems: null,
  beardItemsStatus: DataStatus.unFetched,
  getBeardItems: async (lng: string) => {
    set({ beardItemsStatus: DataStatus.loading });
    const beardItems = await getBeardItems(lng);
    set({ beardItems, beardItemsStatus: DataStatus.fetched });
  }
});

import React, { useEffect, useMemo, useState } from 'react';
import { useLanguage } from './core/i18n';
import { LoadingBox } from './components';
import { BrowserRouter, Switch, withRouter } from 'react-router-dom';
import { getRootSubPath, useTrackBrowserHeight } from './core/helpers';
import { AppStore, Context } from './core/store/event';

const LazyAppRouter = React.lazy(() => import('./routes/AppRouter'));

const appStore = new AppStore();

const Wrapper = withRouter(() => {
  const { i18nLoading } = useLanguage();
  const [arResourceLoaded, setArResourceLoaded] = useState(false);

  useEffect(() => {
    window.addEventListener('load', () => {
      // wait until all of the 8thwall stuffs are available
      setArResourceLoaded(true);
    })

    return () => { }
  }, [])

  return (
    <>
      {!i18nLoading && arResourceLoaded && (
        <Switch>
          <LazyAppRouter />
        </Switch>
      )}
    </>
  )
});

function App() {
  const currentRootPath = useMemo(() => {
    const rootPath = getRootSubPath();
    if (!!rootPath) {
      localStorage.setItem("rootPath", rootPath);
    } else {
      localStorage.removeItem("rootPath");
    }
    return rootPath;
  }, []);

  // browser resize event should be bound here to avoid muiltiple executions
  useTrackBrowserHeight();

  return (
    <Context.Provider value={appStore}>
      <React.Suspense fallback={<LoadingBox open={true} />}>
        <BrowserRouter basename={currentRootPath}>
          <Wrapper />
        </BrowserRouter>
      </React.Suspense>
    </Context.Provider>
  )
}

export default App;

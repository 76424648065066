import {
  IBeardItem,
  IBeardStyle,
  IHomePageContent,
  ITranslationDataType,
  ITutorialStep,
} from 'src/core/declarations/app';
import { getEntries, getLocales } from './contentful.client';
import { ContentTypes } from 'src/core/declarations/enum';

export const getSupportLanguages = () => {
  return getLocales().then(response => {
    return response.items;
  });
}

export const getTranslation = (locale: string) => {
  return getEntries<ITranslationDataType>({
    locale,
    content_type: ContentTypes.translation,
    select: 'fields.translate'
  }).then(res => res.items[0])
}

export const getHomePageContent = (locale: string) => {
  return getEntries<IHomePageContent>({
    locale,
    content_type: ContentTypes.homePageContent,
    select: 'sys.id,fields.backgroundVideo,fields.styling',
  }).then(res => res.items[0])
}

export const getBeardStyles = (locale: string) => {
  return getEntries<IBeardStyle>({
    locale,
    content_type: ContentTypes.beardStyle,
    order: 'fields.beardName'
  })
}

export const getBeardItems = (locale: string) => {
  return getEntries<IBeardItem>({
    locale,
    content_type: ContentTypes.beardItem,
    // 'fields.beardStyle.sys.id': beardStyleId,
  })
}

export const getTutorialSteps = (beardStyleId: string, locale: string) => {
  return getEntries<ITutorialStep>({
    locale,
    content_type: ContentTypes.tutorialStep,
    'fields.beardStyle.sys.id': beardStyleId,
  })
}

import { Box, Fade } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Subject, firstValueFrom, filter } from "rxjs";
import { useAppContext } from "src/core/store/event";
import {
  StepSelectBeard,
  StepScreenShot,
  StepEnterTutorialMode,
  StepSelectBeardBackground,
  StepEnterTutorialModeBackground,
  StepScreenShotBackground,
  INextStepOptions,
} from "./steps";
import { TLocatorID } from "src/core/declarations/app";

interface IUserGuideChild {
  key: string;
  node: React.ReactNode;
}

interface IBackgroundTransitionProps {
  display: boolean;
  animationPrefix?: string;
  locatorId: TLocatorID;
  child?: (
    locatorId: TLocatorID,
    ...ref: React.MutableRefObject<HTMLElement | undefined>[]
  ) => IUserGuideChild[];

  onBackGroundExit?: () => void;
}

const BackgroundAnimation = ({
  display,
  animationPrefix,
  child,
  locatorId,
  onBackGroundExit,
}: IBackgroundTransitionProps) => {
  // fixed number of refs presume that there are no more than 2 transition elements
  const nodeRef = useRef<HTMLElement>();
  const nodeRef2 = useRef<HTMLElement>();

  if (!child) return <></>;

  return (
    <>
      {child(locatorId, nodeRef, nodeRef2).map((el: IUserGuideChild, elIdx) => (
        <CSSTransition
          key={`animation-child-${el.key}`}
          in={display}
          timeout={500}
          classNames={animationPrefix}
          unmountOnExit
          onExit={onBackGroundExit}
          nodeRef={elIdx === 0 ? nodeRef : nodeRef2}
        >
          {el.node}
        </CSSTransition>
      ))}
    </>
  );
};

interface IGuideStep {
  locatorId: TLocatorID;
  background?: (
    locatorId: TLocatorID,
    ...ref: React.MutableRefObject<HTMLElement | undefined>[]
  ) => IUserGuideChild[];
  content: React.ReactNode;
  animation?: string;
}

const stepsElements: (nextStepHandler: () => void) => IGuideStep[] = (
  nextStepHandler
) => {
  return [
    {
      content: (
        <StepSelectBeard onNextStep={nextStepHandler} locatorId={null} />
      ),
      background: StepSelectBeardBackground,
      animation: "expand",
      locatorId: null,
    },
    {
      content: (
        <StepScreenShot
          onNextStep={nextStepHandler}
          locatorId="screenshoot-button"
        />
      ),
      background: StepScreenShotBackground,
      animation: "expand",
      locatorId: "screenshoot-button",
    },
    {
      content: (
        <StepEnterTutorialMode
          onNextStep={nextStepHandler}
          locatorId="go-button"
        />
      ),
      background: StepEnterTutorialModeBackground,
      animation: "expand",
      locatorId: "go-button",
    },
  ];
};

const UserGuidance = () => {
  const [display, setDisplay] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { realityReadyEvent, triggerWaitEndEvent } = useAppContext();

  // effect that triggers next guidance step
  const effectToBeTriggered = useCallback((nextStep: number) => {
    setDisplay(true);
    setTimeout(() => {
      if (nextStep === stepsLength.current) {
        setDisplay(false);
      } else {
        setCurrentStep(nextStep);
      }
    }, 225);
  }, []);

  const onEffectExitEvent = useMemo(() => new Subject<number>(), []);
  const waitEffect = useRef<Function | null>(null);
  const waitEvent = useRef<string | null>(null);
  const stepsLength = useRef<number>();
  const handleNextStep = useCallback(
    (interaction?: () => void, options?: INextStepOptions) => {
      // wait action
      if (options?.shouldWait) {
        let _currentStep: number | null = null;
        // if true guidance will hide away while waiting and current step will be non-specific
        if (options?.shouldHide) {
          _currentStep = currentStep;

          setDisplay(false);
          // put a fake number of step that never reach
          setCurrentStep(999999);
        }

        // wait for previous action/effect to end before triggering interaction/effect in this step
        if (options?.waitBeforeInteraction) {

          firstValueFrom(onEffectExitEvent).then(() => { }).catch(() => { });
        }

        // event that can be triggered from application components
        waitEvent.current = options?.eventToSubscribe ?? null;

        // interaction to call actions from actual application components
        if (interaction) interaction();

        effectToBeTriggered((_currentStep ?? currentStep) + 1);
        // waitEffect cached until triggerWaitEndEvent emits event
        waitEffect.current = effectToBeTriggered;
      } else {
        const nextStep = currentStep + 1;
        if (nextStep === stepsLength.current) {
          setDisplay(false);
        } else {
          setCurrentStep(nextStep);
        }
      }
    },
    [currentStep, onEffectExitEvent, effectToBeTriggered]
  );

  const guidanceSteps = useMemo(
    () => stepsElements(handleNextStep),
    [handleNextStep]
  );

  useEffect(() => {
    stepsLength.current = guidanceSteps.length;
  }, [guidanceSteps]);

  const currentStepContent = useMemo(() => {
    return !guidanceSteps[currentStep] ? (
      <></>
    ) : (
      guidanceSteps[currentStep].content
    );
  }, [currentStep, guidanceSteps]);

  useEffect(() => {
    const subscription = triggerWaitEndEvent.pipe(filter((event) => event === waitEvent.current))
      .subscribe(() => {
        if (waitEffect.current) {
          waitEffect.current();
          waitEffect.current = null;

          // clear wait event when done
          waitEvent.current = "";
        }
      });

    return () => { subscription.unsubscribe(); }
  }, [realityReadyEvent, triggerWaitEndEvent]);

  useEffect(() => {
    const subscription = realityReadyEvent.subscribe(() => {
      setDisplay(true);
    })

    return () => { subscription.unsubscribe(); }
  }, [realityReadyEvent])

  return (
    <>
      <Fade appear={false} in={display} timeout={{ enter: 800, exit: 500 }}>
        <Box
          sx={{
            zIndex: 1400,
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: "rgba(0,0,0,.7)",
            mixBlendMode: "darken",
          }}
        >
          <TransitionGroup>
            {guidanceSteps.map((step, stepIdx) => (
              <BackgroundAnimation
                key={`bg-animation-${step.locatorId}`}
                display={currentStep === stepIdx}
                animationPrefix={step.animation}
                onBackGroundExit={() => {
                  onEffectExitEvent.next(stepIdx);
                }}
                locatorId={step.locatorId}
                child={step.background}
              />
            ))}
          </TransitionGroup>
        </Box>
      </Fade>

      <Fade appear={false} in={display} timeout={{ enter: 800, exit: 500 }}>
        <Box
          sx={{
            zIndex: 1401,
            display: display ? "block" : "none",
            position: "fixed",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }}
        >
          {currentStepContent}
        </Box>
      </Fade>
    </>
  );
};

export default UserGuidance;

export enum QueryKeys {
  translation = 'translation',
  language = 'language',
  homePageContent = 'homePageContent',
  beardStyleContent = 'beardStyleContent',
  beardItem = 'beardItem'
};

export enum ContentTypes {
  beardStyle = "beardStyle",
  beardItem = "beardItem",
  cookieConsent = "cookieConsent",
  homePageContent = "homePageContent",
  sitePrivacyProtection = "sitePrivacyProtection",
  translation = "translation",
  tutorialStep = "tutorialStep"
}

// sanity types
export enum DataTypes {
  beardStyle = 'beardStyle',
  blockContent = 'blockContent',
  button = 'button',
  inlineImage = 'inlineImage',
  supportLanguage = 'supportLanguage',
  palette = 'palette',
  translation = 'translation',
  localeBlockContent = 'localeBlockContent',
  localeJSON = 'localeJSON',
  localeString = 'localeString',
  localeText = 'localeText',
  sanityImageAsset = 'sanity.imageAsset',
  sanityFileAsset = 'sanity.fileAsset',
  homePageContent = "homePageContent"
}

export enum guidanceEvents {
  selectBeard = 'selectBeard',
  enterTutorialMode = 'enterTutorialMode'
}

export enum DataStatus {
  unFetched = 'unFetched',
  loading = 'loading',
  fetched = 'fetched'
}
import { EntryCollection } from "contentful";
import { getBeardStyles } from "src/crud/crud";
import { IBeardStyle } from "../declarations/app";
import { DataStatus } from "../declarations/enum";

export interface IBeardStylesState {
  beardStyles: EntryCollection<IBeardStyle> | null;
  beardStylesStatus: DataStatus;
  getBeardStyles: (lng: string) => void;
  beardColors: string[];
};

export const createBeardStylesState: (...args: any) => IBeardStylesState = (set) => ({
  beardStyles: null,
  beardStylesStatus: DataStatus.unFetched,
  getBeardStyles: async (lng: string) => {
    set({ beardStylesStatus: DataStatus.loading });
    const beardStyles = await getBeardStyles(lng);

    const beardColors = beardStyles.items[0].fields.beardItems.map(v => v.fields.beardColor);
    set({ beardStyles, beardColors, beardStylesStatus: DataStatus.fetched });
  },
  beardColors: []
});

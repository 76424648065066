import { create } from 'zustand';
import { IBeardStylesState, createBeardStylesState } from './beardStyles';
import { IBeardItemsState, createBeardItemsState } from './beardItems';
import { ILanguageState, createLanguageState } from './languages';
import { createHomePageContentState, IHomePageContentState } from './homePageContent';

export type IBoundStore = IBeardStylesState & IBeardItemsState & ILanguageState
  & IHomePageContentState;

const useBoundStore = create<IBoundStore>((...a) => ({
  ...createBeardStylesState(...a),
  ...createBeardItemsState(...a),
  ...createLanguageState(...a),
  ...createHomePageContentState(...a)

}));

export { useBoundStore };

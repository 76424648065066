import { getSupportLanguages } from "src/crud/crud";
import { DataStatus } from "../declarations/enum";
import { Locale } from 'contentful';

export interface ILanguageState {
  supportLanguages: Locale[] | null;
  supportLanguagesStatus: DataStatus;
  getSupportLanguages: () => void;
};

export const createLanguageState: (...args: any) => ILanguageState = (set) => ({
  supportLanguages: null,
  supportLanguagesStatus: DataStatus.unFetched,
  getSupportLanguages: () => {
    set({ supportLanguagesStatus: DataStatus.loading });
    getSupportLanguages().then(supportLanguages => {
      set({ supportLanguages, supportLanguagesStatus: DataStatus.fetched });
    }).catch(() => { });
  }
});

import styled from "@emotion/styled";
import { Box, Grid, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { guidanceEvents } from "src/core/declarations/enum";
import { useAppContext } from "src/core/store/event";
import AppButton from "../AppButton";
import { CurveArrowIcon, HandPointingIcon } from "../icons";
import { TLocatorID } from "src/core/declarations/app";

export interface INextStepOptions {
  waitBeforeInteraction?: boolean;
  shouldWait?: boolean;
  shouldHide?: boolean;
  eventToSubscribe?: string;
}

interface IStepProps {
  /**
   * @param interaction a function that perform interaction with application components
   * @param options 
   */
  onNextStep: (interaction?: () => void, options?: INextStepOptions) => void;
  locatorId: TLocatorID;
}

const useComponentLocation = (locatorId: string | null) => {
  if (!locatorId) return null;
  const el = document.querySelector(`[app-locator="${locatorId}"]`);
  const elPos = el?.getBoundingClientRect();
  if (elPos) {
    return {
      x: elPos.x + elPos.width / 2,
      y: elPos.y + elPos.height / 2,
    };
  }

  return null;
};

const UserGuideTypography = styled(Typography)({
  opacity: 0.8,
  fontWeight: 300,
  fontSize: "1.25rem",
  whiteSpace: "pre-line"
});

const UserGuideCurveArrow = styled(CurveArrowIcon)({
  opacity: 0.8,
});

const UserGuideLeftHandWrapper = styled.div({
  position: "absolute",
  left: "80px",
  animation: "xAxisLeft 2s infinite ease-in",
  "& > div": {
    animation: "yAxis 2s infinite ease-out",
  },
});

const UserGuideRightHandWrapper = styled.div({
  position: "absolute",
  right: "80px",
  animation: "xAxisRight 2s infinite ease-in",
  "& > div": {
    animation: "yAxis 2s infinite ease-out",
  },
});

const UserGuideAnimatedLeftHand = styled(HandPointingIcon)({
  opacity: 0.8,
  fontSize: "40px",
  transform: "rotate(-15deg)",
});

export const StepShareApp = ({ onNextStep }: IStepProps) => {
  const { t } = useTranslation();
  return (
    <>
      <UserGuideTypography
        style={{
          position: "absolute",
          top: "11%",
          right: "72px",
        }}
      >
        {t("GuidanceStepShareApp")}
      </UserGuideTypography>

      <Box
        sx={{
          position: "absolute",
          right: "4%",
          top: "7%",
        }}
      >
        <UserGuideCurveArrow
          sx={{ fontSize: "70px", transform: " rotate(-44deg) scaleY(-1)" }}
        />
      </Box>

      <AppButton
        color="secondary"
        variant="contained"
        sx={{
          position: "absolute",
          top: "25%",
          left: "70%",
        }}
        onClick={() => onNextStep()}
      >
        {t("GuidanceNextButtonText")}
      </AppButton>
    </>
  );
};

export const StepShareAppBackground = (
  locatorId: TLocatorID,
  ...refs: React.MutableRefObject<HTMLElement | undefined>[]
) => [
    <Box
      ref={refs[0]}
      key={'step-share-app-background-0'}
      sx={{
        width: "40px",
        height: "40px",
        position: "absolute",
        right: "7px",
        display: "block",
        backgroundColor: "#fff",
        top: "5px",
        borderRadius: "50%",
      }}
    ></Box>
  ];

export const StepSelectBeard = ({ onNextStep }: IStepProps) => {
  const { interactionEvent } = useAppContext();

  const { t } = useTranslation();
  const selectBeardStyle = () => {
    interactionEvent.next(guidanceEvents.selectBeard);
    onNextStep();
  };

  return (
    <>
      <UserGuideTypography
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "25%",
        }}
      >
        {t("GuidanceStepSelectBeard")}
      </UserGuideTypography>

      <Box
        sx={{
          position: "absolute",
          right: "53%",
          bottom: "14%",
        }}
      >
        <UserGuideCurveArrow
          sx={{ fontSize: "70px", transform: " rotate(84deg) scaleY(-1)" }}
        />
      </Box>

      <AppButton
        id="selectBeardButton"
        sx={{
          borderRadius: "50%",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "30px",
          width: "80px",
          height: "80px",
        }}
        onClick={() => selectBeardStyle()}
      >
        {" "}
      </AppButton>
    </>
  );
};

export const StepSelectBeardBackground = (
  locatorId: TLocatorID,
  ...refs: React.MutableRefObject<HTMLElement | undefined>[]
) => [
    {
      key: "step-select-beard-background",
      node: (<Box
        ref={refs[0]}
        key={"step-select-beard-background"}
        sx={{
          width: "80px",
          position: "absolute",
          height: "80px",
          left: "calc(50% - 40px)",
          display: "block",
          backgroundColor: "#fff",
          bottom: "25px",
          borderRadius: "50%",
        }}
      ></Box>)
    }
  ];

export const StepSwipeBeard = ({ onNextStep }: IStepProps) => {
  const { t } = useTranslation();
  return (
    <>
      <UserGuideTypography
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          top: "43%",
          textAlign: "center",
        }}
      >
        {t("GuidanceStepSwipeBeard")}
      </UserGuideTypography>

      <Box
        sx={{
          position: "absolute",
          left: "5%",
          top: "60%",
        }}
      >
        <UserGuideLeftHandWrapper>
          <div>
            <UserGuideAnimatedLeftHand />
          </div>
        </UserGuideLeftHandWrapper>
        <UserGuideCurveArrow
          sx={{
            fontSize: "80px",
            transform: "translate(10px, -100px) rotate(-170deg) scaleY(-1)",
          }}
        />
      </Box>

      <Box
        sx={{
          position: "absolute",
          right: "5%",
          top: "60%",
        }}
      >
        <UserGuideRightHandWrapper>
          <div>
            <UserGuideAnimatedLeftHand />
          </div>
        </UserGuideRightHandWrapper>

        <UserGuideCurveArrow
          sx={{
            fontSize: "80px",
            transform: "translate(-10px, -100px) rotate(-10deg) scaleY(1)",
          }}
        />
      </Box>

      <AppButton
        color="secondary"
        variant="contained"
        sx={{
          position: "absolute",
          bottom: "35%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        onClick={() => onNextStep()}
      >
        {t("GuidanceNextButtonText")}
      </AppButton>
    </>
  );
};

export const StepScreenShot = ({ onNextStep, locatorId }: IStepProps) => {
  const pos = useComponentLocation(locatorId as string | null);
  const { t } = useTranslation();
  return (
    <Grid
      sx={{
        position: "absolute",
        top: pos?.y ? `${pos?.y}px` : null,
        left: pos?.x ? `${pos?.x}px` : null,
        transform: "translate(-50%,-102%)",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppButton
        id="screenShotNextButton"
        color="secondary"
        variant="contained"
        sx={{
          marginBottom: "10px",
          alignSelf: "center",
        }}
        onClick={() => {
          onNextStep();
        }}
      >
        {t("GuidanceNextButtonText")}
      </AppButton>

      <UserGuideTypography
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        {t("GuidanceStepScreenShot")}
      </UserGuideTypography>

      <Box
        sx={{
          transform: `translateX(${(pos?.x ?? 80) - 80}px)`,
          display: "inline-flex",
        }}
      >
        <UserGuideCurveArrow
          sx={{ fontSize: "70px", transform: " rotate(84deg) scaleY(-1)" }}
        />
      </Box>
    </Grid>
  );
};

const StepScreenShotBackgroundBox = forwardRef(
  ({ locatorId }: { locatorId: string | null }, ref) => {
    const pos = useComponentLocation(locatorId);

    return (
      <Box
        ref={ref}
        sx={{
          width: "60px",
          position: "absolute",
          height: "60px",
          display: "block",
          backgroundColor: "#fff",
          top: `${pos?.y ?? null}px`,
          left: `${pos?.x ?? null}px`,
          transform: "translate(-50%,-50%)",
          borderRadius: "50%",
        }}
      ></Box>
    );
  }
);

export const StepEnterColorMode = ({ onNextStep, locatorId }: IStepProps) => {
  const pos = useComponentLocation(locatorId as string | null);
  const { t } = useTranslation();
  return (
    <Grid
      sx={{
        position: "absolute",
        top: pos?.y ? `${pos?.y}px` : null,
        left: pos?.x ? `${pos?.x}px` : null,
        transform: "translate(-28px,-110%)",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        paddingBottom: "40px"
      }}
    >
      <AppButton
        color="secondary"
        variant="contained"
        sx={{
          marginBottom: "10px",
          alignSelf: "center",
        }}
        onClick={() => {
          onNextStep();
        }}
      >
        {t("GuidanceNextButtonText")}
      </AppButton>

      <UserGuideTypography
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        {t("GuidanceStepEnterColorMode")}
      </UserGuideTypography>

      <Box
        sx={{
          display: "inline-flex",
          position: "absolute",
          bottom: 0
        }}
      >
        <UserGuideCurveArrow
          sx={{ fontSize: "70px", transform: " rotate(121deg) scaleY(-1)" }}
        />
      </Box>
    </Grid>
  );
};

const StepColorBackgroundBox = forwardRef(
  ({ locatorId }: { locatorId: string | null }, ref) => {
    const pos = useComponentLocation(locatorId);

    return (
      <Box
        ref={ref}
        sx={{
          width: "50px",
          position: "absolute",
          height: "50px",
          display: "block",
          backgroundColor: "#fff",
          top: `${pos?.y ?? null}px`,
          left: `${pos?.x ?? null}px`,
          transform: "translate(-50%,-50%)",
          borderRadius: "50%",
        }}
      ></Box>
    );
  }
);

export const StepEnterColorModeBackground = (
  locatorId: TLocatorID,
  ...refs: React.MutableRefObject<HTMLElement | undefined>[]
) => [
    <StepColorBackgroundBox
      key={"step-enter-color-mode-background"}
      locatorId={locatorId as string | null}
      ref={refs[0]}
    />,
  ];

export const StepScreenShotBackground = (
  locatorId: TLocatorID,
  ...refs: React.MutableRefObject<HTMLElement | undefined>[]
) => [
    {
      key: "step-screen-shot-background",
      node: (<StepScreenShotBackgroundBox
        key={"step-screen-shot-background"}
        locatorId={locatorId as string | null}
        ref={refs[0]}
      />)
    },
  ];

export const StepEnterTutorialMode = ({
  onNextStep,
  locatorId,
}: IStepProps) => {
  const pos = useComponentLocation(locatorId as string | null);
  const { t } = useTranslation();

  const enterTutorialMode = () => {
    onNextStep(undefined, {
      shouldWait: true,
      shouldHide: true,
      eventToSubscribe: "showBeardSteps",
    });
  };

  return (
    <Grid
      sx={{
        position: "absolute",
        top: pos?.y ? `${pos?.y - 70}px` : null,
        transform: "translateY(-50%)",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <AppButton
        id="enterTutorialModeButton"
        color="secondary"
        variant="contained"
        sx={{
          alignSelf: "center",
          marginBottom: "10px",
        }}
        onClick={() => enterTutorialMode()}
      >
        {t("GuidanceNextButtonText")}
      </AppButton>

      <UserGuideTypography
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        {t("GuidanceStepEnterTutorialMode")}
      </UserGuideTypography>

      <Box
        sx={{
          alignSelf: "flex-end",
          transform: "translateX(-50px)",
        }}
      >
        <UserGuideCurveArrow
          sx={{ fontSize: "70px", transform: " rotate(52deg) scaleY(-1)" }}
        />
      </Box>
    </Grid>
  );
};

const StepEnterTutorialModeBackgroundBox = forwardRef(
  ({ locatorId }: { locatorId: string | null }, ref) => {
    const pos = useComponentLocation(locatorId);
    return (
      <Box
        ref={ref}
        sx={{
          width: "50px",
          height: "50px",
          display: "block",
          position: "absolute",
          backgroundColor: "#fff",
          top: `${pos?.y ?? null}px`,
          left: `${pos?.x ?? null}px`,
          transform: "translate(-50%,-50%)",
          borderRadius: "50%",
        }}
      ></Box>
    );
  }
);

export const StepEnterTutorialModeBackground = (
  locatorId: TLocatorID,
  ...refs: React.MutableRefObject<HTMLElement | undefined>[]
) => [
    {
      key: "step-enter-tutorial-mode-background",
      node: (<StepEnterTutorialModeBackgroundBox
        key={"step-enter-tutorial-mode-background"}
        locatorId={locatorId as string | null}
        ref={refs[0]}
      />)
    }
  ];

export const StepSwitchBeardSteps = ({ onNextStep }: IStepProps) => {
  const { t } = useTranslation();
  return (
    <Grid
      sx={{
        position: "absolute",
        top: "38%",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <UserGuideTypography
        style={{
          whiteSpace: "pre",
          textAlign: "center",
        }}
      >
        {t("GuidanceStepSwitchBeardSteps")}

      </UserGuideTypography>

      <AppButton
        color="secondary"
        variant="contained"
        sx={{
          marginTop: "10px",
        }}
        onClick={() => onNextStep(undefined, {
          shouldHide: true,
          shouldWait: true,
          eventToSubscribe: "finish-tutorial"
        })}
      >
        {t("GuidanceNextButtonText")}
      </AppButton>
    </Grid>
  );
};

const FirstStepSwitchBeardStepsBackgroundBox = forwardRef(
  ({ locatorId }: { locatorId: string | null }, ref) => {
    const pos = useComponentLocation(locatorId);

    return (
      <Box
        ref={ref}
        sx={{
          width: "65px",
          position: "absolute",
          height: "65px",
          top: `${pos?.y ?? null}px`,
          left: `${pos?.x ?? null}px`,
          transform: "translate(-45%,-50%)",
          display: "block",
          backgroundColor: "#fff",
          borderRadius: "50%",
        }}
      ></Box>
    );
  }
);

const SecondStepSwitchBeardStepsBackgroundBox = forwardRef(
  ({ locatorId }: { locatorId: string | null }, ref) => {
    const pos = useComponentLocation(locatorId);

    return (
      <Box
        ref={ref}
        sx={{
          width: "65px",
          position: "absolute",
          height: "65px",
          top: `${pos?.y ?? null}px`,
          left: `${pos?.x ?? null}px`,
          transform: "translate(-55%,-50%)",
          display: "block",
          backgroundColor: "#fff",
          borderRadius: "50%",
        }}
      ></Box>
    );
  }
);

export const StepSwitchBeardStepsBackground = (
  locatorId: TLocatorID,
  ...refs: React.MutableRefObject<HTMLElement | undefined>[]
) => [
    {
      key: "step-switch-beard-steps-background-0",
      node: (<FirstStepSwitchBeardStepsBackgroundBox
        key={"step-switch-beard-steps-background"}
        locatorId={(locatorId as Array<string | null>)[0]}
        ref={refs[0]}
      ></FirstStepSwitchBeardStepsBackgroundBox>)
    },
    {
      key: "step-switch-beard-steps-background-1",
      node: (<SecondStepSwitchBeardStepsBackgroundBox
        key={"step-switch-beard-steps-background-1"}
        locatorId={(locatorId as Array<string | null>)[1]}
        ref={refs[1]}
      ></SecondStepSwitchBeardStepsBackgroundBox>)
    },
  ];

import { useEffect, useRef } from "react"

/**
 * since the body tag takes full height
 * this is to avoid the content is not trimmed of the screen
*/

const iOS15 = /OS 15/.test(navigator.userAgent);

export const useTrackBrowserHeight = () => {
  const trackBrowserHeight = () => {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  useEffect(() => {
    trackBrowserHeight();

    window.addEventListener('resize', trackBrowserHeight);

    return () => {
      window.removeEventListener('resize', trackBrowserHeight);
    }
  }, [])

  // force scroll top on iOS 15 to fix navigation bar minimizing issue in Safari
  useEffect(() => {

    if (iOS15) {
      const documentScrollHandler = () => {
        window.document.documentElement.scrollTop = 0;
      };

      document.addEventListener('scroll', documentScrollHandler);

      return () => { document.removeEventListener('scroll', documentScrollHandler) }
    }
  }, [])
}

/**
 * Remember previous value
*/
export const usePrevious = (value: any) => {
  const ref = useRef<any>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const deepCopyObject = <T extends object>(obj: T): T => {
  if (Array.isArray(obj)) {
    let newArray: Array<any> = [];
    obj.forEach(item => {
      newArray.push(deepCopyObject(item));
    });

    return (newArray as T);
  }

  if (typeof obj === 'object') {
    let newObject: { [key: string]: any } & object = {};
    const objKeys = Object.keys(obj);
    objKeys.forEach((key: string) => {
      newObject[key] = deepCopyObject((obj as { [key: string]: any })[key]);
    })
    return (newObject as T);
  }

  return obj;
}

export const isIOS = () => typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

export const getRootSubPath = () => {
  const href = window.location.href;
  // eslint-disable-next-line no-useless-escape
  const pathGroups = href.match(/^(https|http):\/\/[^\/]+((\/[^\/]+)*)\/.*$/m);
  if (pathGroups) {
    return pathGroups[3] ? pathGroups[2] : undefined;
  }
  return undefined;
}

import { Entry } from "contentful";
import { getHomePageContent } from "src/crud/crud";
import { IHomePageContent } from "../declarations/app";
import { DataStatus } from "../declarations/enum";

export interface IHomePageContentState {
  homePageContent: Entry<IHomePageContent> | null,
  homePageContentStatus: DataStatus,
  getHomePageContent: (locale: string) => void;
};

export const createHomePageContentState: (...args: any) => IHomePageContentState = (set) => ({
  homePageContent: null,
  homePageContentStatus: DataStatus.unFetched,
  getHomePageContent: (locale: string) => {
    set({ homePageContentStatus: DataStatus.loading });
    getHomePageContent(locale).then(homePageContent => {
      set({ homePageContent, homePageContentStatus: DataStatus.fetched });
    }).catch(() => {})
  }
});

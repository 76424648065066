import i18n from "i18next";
import { initReactI18next, useTranslation } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import { useEffect, useState } from "react";
import { getTranslation } from 'src/crud/crud';
import { useBoundStore } from "../store";

const loadLanguage = (
  lng: string,
  translation: object,
) => new Promise((resolve: (value: string) => void, reject: (err: string) => void) => {

  const resources = {
    [lng]: {
      default: translation
    }
  };

  i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      // debug: true,
      fallbackLng: ['en'],
      lng,
      resources,
      keySeparator: '>',
      saveMissing: false,
      nsSeparator: '|',
      ns: ['default'],
      defaultNS: 'default',
    }).then(() => {
      resolve(i18n.language);
    }).catch(err => {
      reject(err);
    });
});

export const useLanguage = () => {
  const [i18nLoading, set18nLoading] = useState(true);
  const { supportLanguages, getSupportLanguages } = useBoundStore(({ supportLanguages, getSupportLanguages }) => ({ supportLanguages, getSupportLanguages }));

  useEffect(() => {
    getSupportLanguages();
  }, [getSupportLanguages])

  useEffect(() => {
    if (supportLanguages) {
      const lngFromNavigator = window.navigator.language;
      const defaultLng = supportLanguages.find(d => !!d.default);
      const lngExists = supportLanguages.find(d => d.code === lngFromNavigator);
      const firstLng = (lngExists?.code ?? defaultLng?.code) as string;
      getTranslation(firstLng).then(data => {
        const translation = data.fields.translate;
        loadLanguage(firstLng, translation).then(() => {
          set18nLoading(false);
          localStorage.setItem('i18nextLng', firstLng);
        }).catch(() => { });
      }).catch(() => { });

      i18n.on('loaded', function () {
        // if loaded language is not ready => fallback to en
        const hasLng = i18n.hasResourceBundle(i18n.language, 'default');
        if (!hasLng) {
          const defaultLanguage = supportLanguages?.find(l => l.default);
          if (defaultLanguage) i18n.changeLanguage(defaultLanguage.code).catch(() => { });
        }
      });
    }

  }, [supportLanguages])

  return { i18nLoading };
}

export const useChangeLanguage = () => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const changeLanguage = (lng: string) => {
    setCurrentLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  }

  useEffect(() => {
    if (i18n.language === currentLanguage) return;
    const translationReady = i18n.hasResourceBundle(currentLanguage, 'default');

    if (!translationReady) {
      getTranslation(currentLanguage).then(data => {
        const translation = data.fields.translate;
        i18n.addResourceBundle(currentLanguage, 'default', translation);
        i18n.changeLanguage(currentLanguage).catch(() => { });
      }).catch(() => { });
    } else {
      i18n.changeLanguage(currentLanguage).catch(() => {});
    }
  }, [currentLanguage, i18n])

  return { changeLanguage };
}

// implementation from https://github.com/nguyenvanduocit/vue-loading-spinner/blob/master/src/components/Circle8.vue
// also implemented from P&G package
import { styled } from '@mui/styles';
import React from 'react';

const AnimatedSpinnerWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transform: 'scale(1.2)',
  '& .ball-container': {
    animation: 'animball_two 2000ms infinite',
    width: '44px',
    height: '44px',
    flexShrink: 0,
    position: 'relative',
  },
  '& .contener_mixte': {
    width: '44px',
    height: '44px',
    position: 'absolute',
  },
  '& .ballcolor': {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
  },
  '& .ball_1':
  {
    position: 'absolute',
    animation: 'animball_one 1500ms infinite ease',
    backgroundColor: 'black',
    top: 0, left: 0,
  },
  '& .ball_2':
  {
    position: 'absolute',
    animation: 'animball_one 1500ms infinite ease',
    backgroundColor: '#f8b334',
    top: 0, left: '24px',
  },
  '& .ball_3':
  {
    position: 'absolute',
    animation: 'animball_one 1500ms infinite ease',
    backgroundColor: '#006BCB',
    top: '20px', left: '12px',
  }
})

export const AppSpinner = () => (<AnimatedSpinnerWrapper>
  <div className="spinner-inner">
    <div className="ball-container">
      <div className="contener_mixte"><div className="ballcolor ball_1">&nbsp;</div></div>
      <div className="contener_mixte"><div className="ballcolor ball_2">&nbsp;</div></div>
      <div className="contener_mixte"><div className="ballcolor ball_3">&nbsp;</div></div>
    </div>
  </div>
</AnimatedSpinnerWrapper>)

const LoadingBox = React.memo(({ open, styles }: { open?: boolean, styles?: React.CSSProperties }) => {
  return (
    <div
      className='loading-box'
      style={{
        display: open ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#fff',
        height: '100%',
        zIndex: 9999,
        position: 'fixed',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        ...styles
      }}>
      <AppSpinner />
    </div>
  );
});

export default LoadingBox;

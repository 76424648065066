import { Theme } from '@mui/material';
import { createContext, useContext } from 'react';
import { BehaviorSubject, Subject } from 'rxjs';
import { guidanceEvents } from '../declarations/enum';

// FIXME refactor
interface ICredential { username: string, password: string };
export class AppStore {
  // trigger page loading screen
  // appLoadingStateEvent = new BehaviorSubject<boolean>(false);
  headerToggleEvent = new Subject<boolean>();
  productClaimToggleEvent = new Subject<boolean>();
  arResourcesLoadEvent = new Subject<any>();
  realityReadyEvent = new Subject<any>();
  infoMenuEvent = new Subject<any>();
  appCredential: BehaviorSubject<ICredential> = new BehaviorSubject({ username: '', password: '' });
  appTheme: BehaviorSubject<Theme> = new BehaviorSubject({} as Theme);
  interactionEvent: Subject<guidanceEvents> = new Subject();
  triggerWaitEndEvent: Subject<string> = new Subject();
}

export const Context = createContext<AppStore>(new AppStore());

export const useAppContext = (): AppStore => useContext(Context);
